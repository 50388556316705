import {
  Action,
  AnyAction,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';
// import logger from "redux-logger";
import api from 'lib/api/api';
import sessionSlice from 'lib/auth/sessionSlice';
import { createWrapper } from 'next-redux-wrapper';

const appReducer = combineReducers({
  session: sessionSlice.reducer,
  // RTK Query
  [api.reducerPath]: api.reducer,
});

// Root reducer to listen to all actions
const rootReducer = (state: any | undefined, action: AnyAction) => {
  // If the action is a log out action, reset the store
  // if (action.type === 'session/clearSessionData/fulfilled') {
  // if (clearSessionData.fulfilled.match(action)) {
  //   console.debug('Resetting the redux store because the user is logging out');
  //   // Reset the store
  //   return appReducer(undefined, action);
  // }

  return appReducer(state, action);
};

// const sentryReduxEnhancer = Sentry.createReduxEnhancer({
//   // Remove the session information from state before sending it
//   stateTransformer: state => {
//     return {
//       ...state,
//       session: undefined,
//     };
//   },
//   actionTransformer: action => {
//     // Don't include session data
//     if (action.type === 'session/loadSessionData/fulfilled') {
//       return null;
//     } else if (action.type === 'session/saveSessionData/fulfilled') {
//       return null;
//     }

//     return action;
//   },
// });

export const makeStore = () =>
  configureStore({
    reducer: rootReducer,
    // enhancers: [sentryReduxEnhancer],
    middleware: getDefaultMiddleware => {
      const middleware = getDefaultMiddleware().concat(api.middleware);

      // if (process && process.env.NODE_ENV === "development") {
      //   middleware.concat(logger);
      // }

      return middleware;
    },
  });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;

export const wrapper = createWrapper<AppStore>(makeStore);
