// If you need to know if you are in the app or not, wrap
// components in this context and then then you can use the
// `useInAppLinking` hook to use push or window.location depending
// on if you are in the app or not.
import { useRouter } from 'next/router';
import React from 'react';

const IsAppContext = React.createContext(false);

// Hook to get access to this
export default function useInAppLinking() {
  const isApp = React.useContext(IsAppContext);
  const router = useRouter();

  return {
    isApp,
    push(url: string) {
      // console.log({ isApp });
      if (isApp) {
        window.location.href = url;
      } else {
        router.push(url);
      }
    },
  };
}

export { IsAppContext };
