import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SessionState = {
  cookie: string | null;
};

const initialState = { cookie: null } as SessionState;

export const sessionSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setCookie(state, action: PayloadAction<string | null>) {
      state.cookie = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCookie } = sessionSlice.actions;

export default sessionSlice;
