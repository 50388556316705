import { cache } from '@emotion/css';
import { CacheProvider } from '@emotion/react';
import { IsAppContext } from 'lib/hooks/useInAppLinking';
import { wrapper } from 'lib/store';
import type { AppProps } from 'next/app';
import { Toaster } from 'react-hot-toast';
import 'react-loading-skeleton/dist/skeleton.css';
import '../styles/globals.css';

// import GlobalStyles from "styles/GlobalStyles";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <CacheProvider value={cache}>
      <IsAppContext.Provider value={false}>
        {/* Apparently we are supposed to use this but it messed up
      the text inputs for me... */}
        {/* <GlobalStyles /> */}
        <Toaster position="top-right" />
        <Component {...pageProps} />
      </IsAppContext.Provider>
    </CacheProvider>
  );
}

// export default MyApp;
export default wrapper.withRedux(MyApp);
